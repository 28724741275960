import React from 'react'
import PropTypes from 'prop-types'

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = '', childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
        <div className="imageStyle" style={{
          backgroundImage: `url(${
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}>
        </div>
    )
  }

  if (!!childImageSharp) {
    return (
        <div className="imageStyle" style={{
          backgroundImage: `url(${
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}>
        </div>
    )
  }

  if (!!image && typeof image === 'string')
    return (
        <div className="imageStyle" style={{
            backgroundImage: `url(${
                !!image ? image : image
            })`,
        }}>
        </div>
    )

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImage
